/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const breadcrumbTrailContainerSX: SxProps<Theme> = (theme) => ({
	'&:first-of-type': {
		mt: '0',
		paddingTop: '16px',
	},
	'.MuiBreadcrumbs-ol': {
		// padding: (theme) => `${theme.spacing(2)} 0`,
		margin: '0 0 32px 10px',
		gap: (theme) => theme.spacing(1),
	},
	'.MuiBreadcrumbs-li, .MuiBreadcrumbs-li a': {
		color: 'text.main',
		fontSize: '12px',
		fontWeight: '600',
		padding: '0',
	},
	'.MuiBreadcrumbs-li a': {
		color: 'button.blue',
		fontWeight: '400',
	},
	'.MuiBreadcrumbs-li a:hover': {
		textDecoration: 'underline',
		background: 'none',
	},
	'.MuiBreadcrumbs-separator': {
		color: 'icons.fillcolor',
	},
});
